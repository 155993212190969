<template>
  <div>
    <div class="important-news">
      <div class="text-container">
        <h3 class="text-center">
          Aktuell: Der pharmazeutische Großhandel kürzt Ihre Konditionen!
        </h3>
        <p>
          Derzeit erwarten Sie gravierende Veränderungen bei Ihren
          Großhandelsbezügen. Per Brief oder im persönlichen Gespräch werden
          diese Veränderungen mit dem rasanten Anstieg der Energiekosten,
          Personalkosten und Schmälerung der eigenen Einkaufsrabatte begründet.
          Hier stellen sich Fragen wie:
        </p>
        <ul>
          <li>Sind die geforderten Kürzungen in ihrer Höhe berechtigt?</li>
          <li>Passen die Kürzungen zum Einkaufsverhalten meiner Apotheke?</li>
          <li>
            Nutzen Sie bereits heute alle Konditionsmöglichkeiten beim
            Großhandel?
          </li>
        </ul>
        <p>
          Gerne helfen wir Ihnen um aus diesen Verhandlungen als Sieger
          hervorzugehen. Wir stehen mit 35 Jahren Erfahrung aus dem
          pharmazeutischen Großhandel an Ihrer Seite. Erteilen Sie uns Ihr
          Mandat und wir überprüfen und verhandeln Ihre Großhandelskonditionen.
        </p>
        <p>
          Senden Sie uns Ihre Sammelrechnung kostengünstig und unverbindlich auf
          elektronischem Wege an
          <a href="mailto:info@awh-beratung.de">info@awh-beratung.de</a>.
        </p>
        <div class="text-center mt-3">
          <b-button
            variant="primary"
            class="button px-xl-4 px-sm-1"
            href="#contact"
          >
            Jetzt Kontakt aufnehmen
          </b-button>
        </div>
      </div>
    </div>

    <div class="text-container py-4">
      <h3 class="text-center">Unsere Leistung</h3>
      <p>
        Den signifikant größten Teil Ihres Einkaufvolumens erhält der
        pharmazeutische Großhandel. In der richtigen Bewertung und Verhandlung
        der Konditionen liegen erhebliche Potentiale. Hier helfen Ihnen unsere
        Marktübersicht und jahrzehntelange Erfahrung im pharmazeutischen
        Großhandel.
      </p>
      <ul>
        <li>
          Da sich der Markt in der heutigen Zeit ständig verändert und somit
          auch das Umfeld, ist man gezwungen, besonderen Wert auf den
          betriebswirtschaftlichen Erfolg zu legen.
        </li>
        <li>
          Dies setzt voraus, dass Sie eine zeitnahe Kontrolle Ihrer
          Großhandelsrechnung vornehmen.
        </li>
        <li>
          Es müssen die vereinbarten Konditionen auf dem Konditionsblatt des
          Großhandels überprüft werden. Z. B. Überprüfung von Skonti, Prüfung
          von Boni auf RX und NON-RX, Prüfung der Retourenquote und Gebühren,
          zeitnahe Einforderung von Falschberechnungen.
          <b>
            Dieses gibt Ihnen ein hohes Maß an betriebswirtschaftlicher
            Planungssicherheit und wirkt sich direkt auf Ihr Ergebnis aus.
          </b>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.important-news {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f2d3ce;
}
</style>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir helfen Ihnen bei der Bewertung und Verhandlung von Großhandelskonditionen. Profitieren Sie von unserer Marktübersicht und jahrzehntelangen Erfahrung im pharmazeutischen Großhandel.",
      },
      {
        property: "og:image",
        content:
          "https://awh-beratung.de/assets/visuals/beratung-wirtschaft.png",
      },
    ],
  },
};
</script>